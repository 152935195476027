<template>
  <v-card id="account-setting-card">
    <div class="row" v-if="objOnline">
      <div class="col-sm-4">
        <v-col cols="12">
          <v-card color="#00C853" dark :elevation="5">
            <v-card-title class="headline"> <v-icon>mdi-account</v-icon></v-card-title>
            <v-card-actions>
              <v-btn text>Is {{ objOnline.isOnline }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
      <div class="col-sm-4">
        <v-col cols="12">
          <v-card color="#fc7e2f" dark :elevation="5">
            <v-card-title class="headline">Battery <v-icon>mdi-battery</v-icon></v-card-title>
            <v-card-subtitle> Is Pluging {{ obj.isCharging }} </v-card-subtitle>
            <v-card-actions>
              <v-btn text>{{ obj.battryPercentage }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
      <div class="col-sm-4">
        <v-col cols="12">
          <v-card color="#020887" dark :elevation="5">
            <v-card-title class="headline">Bluetooth <v-icon>mdi-bluetooth</v-icon></v-card-title>
            <!-- <v-card-subtitle>Listen to your favorite artists and albums whenever and wherever, online and offline.                
            </v-card-subtitle> -->
            <v-card-actions>
              <v-btn text>{{ obj.bluetooth }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
      <br />
      <div class="col-sm-4">
        <v-col cols="12">
          <v-card color="#2ec4b6" dark :elevation="5">
            <v-card-title class="headline">Person phone is <v-icon>mdi-cellphone </v-icon></v-card-title>
            <v-card-actions>
              <v-btn text
                ><b>{{ obj.sound }}</b></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
      <div class="col-sm-4">
        <v-col cols="12">
          <v-card color="#385F73" dark :elevation="5">
            <v-card-title class="headline">Mobile Data is <v-icon>mdi-flash</v-icon></v-card-title>
            <!-- <v-card-subtitle>Listen to your favorite artists and albums whenever and wherever, online and offline.                
            </v-card-subtitle> -->
            <v-card-actions>
              <v-btn text>{{ obj.mobileDataType }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
      <!-- <div class="col-sm-4">
        <v-col cols="12">
          <v-card color="#952175" dark>
            <v-card-title class="headline">Unlimited music now</v-card-title>
            <v-card-subtitle>Listen to your favorite artists and albums whenever and wherever, online and offline.                
            </v-card-subtitle>
            <v-card-actions>
              <v-btn text>Listen Now</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </div> -->
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { doc, getDoc } from 'firebase/firestore'
import { dbRef, dashboardClick } from '../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../localStorage'
export default {
  components: {},
  mounted: function () {
    this.getAppConfig()
    dashboardClick()
  },
  methods: {
    async getAppConfig() {
      this.$store.commit('LOADER', true);
      let uid = getUID()
      const myDoc = doc(dbRef, 'AppConfig', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
      getDoc(myDoc)
        .then(res => {
          console.log(res)
          console.log(res.data())
          this.obj = res.data()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },

    // async myD() {
    //   if (this.selected == null) {
    //     console.log(this.selected);
    //   } else if (this.selected == "Warriyo") {
    //     console.log(this.selected);
    //   } else if (this.selected == "LostSky") {
    //     console.log(this.selected);
    //   } else if (this.selected == "Jasico") {
    //     console.log(this.selected);
    //   } else if (this.selected == "Electro") {
    //     console.log(this.selected);
    //   }
    // },
    // getObj() {
    //   return appConfig
    //     .child("78KOHWXhC0UTR3fxqkqsoJ7j1Ew1")
    //     .child("868068040704064")
    //     .on("value", snapshot => {
    //       this.obj = snapshot.val();
    //       console.log(this.obj);
    //     });
    // },
    // saveBrightness() {
    //   this.obj.flashlight = this.switch1;
    //     appConfig
    //       .child("78KOHWXhC0UTR3fxqkqsoJ7j1Ew1")
    //       .child("868068040704064")
    //       .set(this.obj);
    //   this.text = "successfully Changes";
    //   this.snackbar = true;
    //   this.color = "success";
    // }
  },
  data() {
    return {
      flashlight: '',
      obj: {
        appVibration: {
          seconds: '',
          vibration: '',
        },
        battryPercentage: '',
        isCharging: '',
        bluetooth: '',
        brightness: '',
        flashlight: '',
        mobileData: '',
        mobileDataType: '',
        musicUrl: {
          music: '',
          url: '',
        },
        sound: '',
      },
      objOnline: {
        isOnline: 'offline',
        last_active: '',
      },
    }
  },
}
</script>
